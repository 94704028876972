body {
  color: #212529;
  font-family: Lato, sans-serif;
  font-style: normal;
  font-weight: 400;
}

p {
  font-size: 1.1rem;
}

a.contact-link {
  color: inherit;
  text-decoration: none;
}

a.contact-link:hover {
  text-decoration: underline #e4b74e;
}

.img-featurette {
  transition: transform .2s;
  box-shadow: 0 4px 8px #0003, 0 6px 20px #00000030;
}

.img-featurette:hover {
  transform: scale(1.05);
}

.btn-top-bar-soumission {
  color: #e4b74e;
  border-color: #e4b74e;
}

.btn-top-bar-soumission:hover, .btn-contact {
  color: #212529;
  background-color: #e4b74e;
}

.btn-contact:hover {
  color: #e4b74e;
  background-color: #212529;
}
/*# sourceMappingURL=contact.2e829bd9.css.map */
