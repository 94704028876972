body {
    font-family: "Lato", sans-serif;
    font-weight: 400;
    font-style: normal;
    color: #212529;
}

p {
    font-size: 1.1rem;
}

a.contact-link {
    color: inherit;
    text-decoration: none;
}

a.contact-link:hover {
    text-decoration: underline;
    text-decoration-color: #e4b74e;
}

.img-featurette {
    transition: transform 0.2s;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.img-featurette:hover {
    transform: scale(1.05);
}

.btn-top-bar-soumission {
    color: #e4b74e;
    border-color: #e4b74e;
}

.btn-top-bar-soumission:hover{
    background-color: #e4b74e;
    color: #212529;
}

.btn-contact {
    background-color: #e4b74e;
    color: #212529;
}

.btn-contact:hover {
    background-color: #212529;
    color: #e4b74e;
}